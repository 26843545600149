import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function OttawaFutureTechSector () {
  const shareTitle = 'What does the future hold for Ottawa’s tech sector in 2022?'
  const articleLink = 'https://obj.ca/article/techopia/what-does-future-hold-ottawas-tech-sector-2022-0'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>What does the future hold for Ottawa’s tech sector in 2022?</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>Dec 15, 2021</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/ottawa-tech.jpg" alt="Internet database grid imagery" />
                <div className="snippet">The following preview is from the <a href={articleLink} target="_blank" rel='noreferrer'>Ottawa Business Journal</a></div>
                <p>Quain agrees, pointing to GoFor and fellow Ottawa-based delivery startup Trexity as examples of local businesses catering to the other end of the e-commerce chain that are coming into their own.</p>
                <a href={articleLink} target='_blank' rel='noreferrer'>Read more at the Ottawa Business Journal</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/ottawa-teams-up-to-boost-local" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Ottawa Board of Trade, BIA coalition team up on discount, delivery programs to boost local businesses</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
